import React from 'react';
import './index.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Logo from './img/logo.png';
import Home from './components/Home';
import HireMe from './components/HireMe';
import Nav from './components/Nav';
import MobileNav from './components/MobileNav';

function App() {
	return (
		<Router>
			<div className='App min-h-screen bg-gradient-to-tr from-black via-black to-gray-800 text-gray-200 font-sans'>
				<header className='flex mx-auto container justify-between items-center pt-10 p-4'>
					<Link to='/' className='flex items-center'>
						<img src={Logo} className='border-2 p-1 mr-2 rounded-md' width='50' height='50' alt='logo' />
						<h1 className='text-3xl font-bold pt-1'>Terence Waldeck</h1>
					</Link>
					<Nav />
				</header>
				<MobileNav />

				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='hire-me' element={<HireMe />} />

					<Route path='*' element={<p className='text-center text-3xl mt-48'>Page Not Found</p>} />
				</Routes>
			</div>
		</Router>
	);
}

export default App;
