import React from 'react';

const HireMe = () => {
	return (
		<section
			id='hire-me'
			className='block container mx-auto px-4 mb-12 w-10/12 lg:w-8/12 border-green-600 border-l-4 border-r-4 text-center '>
			<h5 className='text-xl md:text-2xl mb-4 underline'>Hire Me</h5>
			<p className='text-gray-300 font-semibold mb-1'>
				Available for Full-time/Part-time employment on both contract or freelance basis.
			</p>
			<p className='text-gray-400 mb-1'>Terence R Waldeck</p>
			<p className='text-gray-400 mb-1'>0781603120</p>
			<a href='mailto:terenceryanmusic@gmail.com' className='text-green-500 mb-1 underline'>
				terenceryanmusic@gmail.com
			</a>
		</section>
	);
};

export default HireMe;
