import React from 'react';
import Hero from './Hero';
import About from './About';
import Projects from './Projects';
import Skills from './Skills';
import HireMe from './HireMe';
import Footer from './Footer';

const Home = () => {
	return (
		<div className='mx-auto p-4'>
			<Hero />
			<About />
			<Projects />
			<Skills />
			<HireMe />
			<Footer />
		</div>
	);
};

export default Home;
