import { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { MenuAlt3Icon } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/solid';

const MobileNav = () => {
	const [open, setOpen] = useState(false);
	const menu = open ? <XIcon className='h-6' /> : <MenuAlt3Icon className='h-6' />;
	return (
		<div>
			<button className='absolute top-14 right-10 md:hidden' onClick={() => setOpen(!open)}>
				{menu}
			</button>
			{open && (
				<nav className='bg-gray-900 bg-opacity-5 md:hidden px-12 '>
					<ul className='grid justify-center gap-4 font-bold'>
						<li>
							<HashLink to='/' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
								Home
							</HashLink>
						</li>
						<li>
							<HashLink to='/#about' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
								About
							</HashLink>
						</li>
						<li>
							<HashLink to='/#projects' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
								Projects
							</HashLink>
						</li>
						<li>
							<HashLink to='/#skills' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
								Skills
							</HashLink>
						</li>
						<li className='border-2 border-green-300 py-1 px-2 rounded text-green-300'>
							<HashLink to='/#hire-me' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
								Hire Me
							</HashLink>
						</li>
					</ul>
				</nav>
			)}
		</div>
	);
};

export default MobileNav;
