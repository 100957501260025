import React from 'react';
import { DiGithub } from 'react-icons/di';
import { SiLinkedin } from 'react-icons/si';

const Footer = () => {
	return (
		<footer className='flex w-full lg:w-08/12 items-center justify-center '>
			<div>Terence Waldeck - Developer Portfolio - &copy; 2022</div>
			<div className='flex'>
				<button className='flex items-center ml-2 p-2 bg-gray-800 rounded'>
					<a href='https://github.com/terenceryanmusic' rel='noreferrer' className='text-gray-300' target='_blank'>
						<DiGithub className='h-5 w-5' />
					</a>
				</button>
				<button className='flex items-center ml-2 p-2 bg-blue-800 rounded'>
					<a
						href='https://www.linkedin.com/in/terence-r-waldeck-1a693272/'
						rel='noreferrer'
						className='text-gray-300'
						target='_blank'>
						<SiLinkedin className='h-5 w-5' />
					</a>
				</button>
			</div>
		</footer>
	);
};

export default Footer;
