import React from 'react';
import { DiHtml5, DiCss3, DiJavascript, DiReact, DiNodejs, DiPhp, DiWordpress } from 'react-icons/di';
import { SiTailwindcss } from 'react-icons/si';

const Skills = () => {
	return (
		<section id='skills' className='block mx-auto p-8 bg-gray-900 rounded-lg w-10/12 lg:w-8/12 my-16'>
			<h4 className='text-3xl mb-6'>Skills</h4>
			<div className='grid grid-cols-1 gap-3 md:grid-cols-2 items-center justify-between'>
				<div className='flex items-center bg-gray-700 p-2 rounded-md'>
					<DiHtml5 className='mr-2 h-10 w-10' />
					<p className='font-semibold text-md'>HTML5</p>
				</div>
				<div className='flex items-center bg-gray-700 p-2 rounded-md'>
					<DiCss3 className='mr-2 h-10 w-10' />
					<p className='font-semibold text-md'>CSS3</p>
				</div>
				<div className='flex items-center bg-gray-700 p-2 rounded-md'>
					<DiJavascript className='mr-2 h-10 w-10' />
					<h4 className='font-semibold text-md'>JavaScript</h4>
				</div>
				<div className='flex items-center bg-gray-700 p-2 rounded-md'>
					<DiReact className='mr-2 h-10 w-10' />
					<p className='font-semibold text-md'>React/Vue</p>
				</div>
				<div className='flex items-center bg-gray-700 p-2 rounded-md'>
					<SiTailwindcss className='mr-2 h-10 w-10' />
					<p className='font-semibold text-md'>TailwindCSS</p>
				</div>
				<div className='flex items-center bg-gray-700 p-2 rounded-md'>
					<DiNodejs className='mr-2 h-10 w-10' />
					<p className='font-semibold text-md'>NodeJS/Express</p>
				</div>
				<div className='flex items-center bg-gray-700 p-2 rounded-md'>
					<DiPhp className='mr-2 h-10 w-10' />
					<p className='font-semibold text-md'>PHP/Laravel</p>
				</div>
				<div className='flex items-center bg-gray-700 p-2 rounded-md'>
					<DiWordpress className='mr-2 h-10 w-10' />
					<p className='font-semibold text-md'>WordPress</p>
				</div>
			</div>
		</section>
	);
};

export default Skills;
