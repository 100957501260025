import React from 'react';
import { HashLink } from 'react-router-hash-link';
import WebDeveloper from './../img/web_developer.svg';
import { motion } from 'framer-motion/dist/framer-motion';
import { DiGithub } from 'react-icons/di';
import { SiLinkedin } from 'react-icons/si';

const Hero = () => {
	return (
		<motion.div
			initial={{ y: 50, opacity: 0 }}
			animate={{ y: 0, opacity: 1 }}
			transition={{ ease: 'easeOut', duration: 2 }}>
			<section id='home' className='min-h-full my-24 block w-full mx-auto'>
				<div className='block w-full md:w-10/12 lg:8/12 mx-auto'>
					<div className='grid justify-center items-stretch grid-cols-1 lg:grid-cols-2'>
						<h1 className='font-poppins font-bold text-center text-6xl md:text-7xl lg:text-8xl leading-0 z-10'>
							Building Beautiful Web Experiences
						</h1>
						<img className='block mx-auto w-96 mt-8 lg:mt-0 z-0' src={WebDeveloper} alt='web-developer' />
					</div>
					<div className='flex justify-center items-end'>
						<HashLink to='/#projects' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
							<button className='block mx-auto mt-12 w-24 px-4 py-2 rounded-sm font-semibold text-gray-800 bg-green-500'>
								Projects
							</button>
						</HashLink>
						<button className='flex items-center ml-2 p-2 h-10 bg-gray-800 rounded'>
							<DiGithub className='h-8 w-8' />
							<a
								href='https://github.com/terenceryanmusic'
								rel='noreferrer'
								className='text-gray-300 w-16 '
								target='_blank'>
								GitHub
							</a>
						</button>
						<button className='flex items-center ml-2 p-2 h-10 bg-blue-800 rounded'>
							<SiLinkedin className='mr-2 h-6 w-6' />
							<a
								href='https://www.linkedin.com/in/terence-r-waldeck-1a693272/'
								rel='noreferrer'
								className='text-gray-300'
								target='_blank'>
								LinkedIn
							</a>
						</button>
					</div>
				</div>
			</section>
		</motion.div>
	);
};

export default Hero;
