import React from 'react';
import ProfileImg from './../img/profile.jpg';
import { motion } from 'framer-motion/dist/framer-motion';

const About = () => {
	return (
		<motion.div
			initial={{ x: -50, opacity: 0 }}
			animate={{ x: 0, opacity: 1 }}
			transition={{ ease: 'easeOut', duration: 2 }}>
			<section
				id='about'
				className='block mx-auto w-10/12 lg:w-8/12 mb-20 p-8 rounded-lg bg-gradient-to-br from-gray-800 to-gray-900'>
				<h2 className='mb-8 text-4xl md:text-5xl text-center md:text-left'>About</h2>
				<motion.div
					initial={{ x: 50, opacity: 0 }}
					animate={{ x: 0, opacity: 1 }}
					transition={{ ease: 'easeIn', duration: 1.5 }}>
					<div className='mb-8 flex flex-col md:flex-row items-center '>
						<img
							className='w-48 mr-0 mb-4 md:mb-0 md:mr-8 rounded-lg md:rounded-full border-2 border-green-500'
							src={ProfileImg}
							alt='profile of developer'
						/>
						<p className='items-center text-lg md:text-xl'>
							Hi I'm Terence, <span className='font-bold text-green-500'>Frontend Developer</span> and 2022 Computer
							Science Graduate from Northumbria University Newcastle. My experience includes: Media Team leader as well
							as Freelance Designer and Developer. My passion is to deliver user centred solutions to enable businesses
							to deliver better web experiences.
						</p>
					</div>
				</motion.div>
			</section>
		</motion.div>
	);
};

export default About;
