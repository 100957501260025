import React from 'react';
import ttm from './../img/talk-to-me.png';
import kf6012 from './../img/kf6012.png';
import cb from './../img/chris-blackburn.png';
import bbu from './../img/bbu.png';

const Projects = () => {
	return (
		<section id='projects' className='block mx-auto my-8 w-10/12 lg:w-8/12 mb-8'>
			<h3 className='text-3xl md:text-4xl mb-8'>Projects</h3>
			<h4 className='text-lg md:text-xl mb-4'>Latest Projects</h4>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-2 '>
				<div>
					<img src={ttm} alt='' className='mb-4 h-64 w-full' />
					<a
						href='https://ttm-kv6003.herokuapp.com/'
						target='_blank'
						rel='noreferrer'
						className='underline text-green-600'>
						Talk To Me
					</a>
					<p className='text-gray-400 mt-2'>
						Final year university dissertation. A mental health app for students using chat, bookings and fitness goals
						features.
					</p>
					<p className='text-green-600 text-sm'>Tech stack: MongoDB, Express, React, Node </p>
				</div>
				<div>
					<img src={kf6012} alt='' className='mb-4 h-64 w-full' />
					<a
						href='http://unn-w19035270.newnumyspace.co.uk/kf6012/coursework/part2/'
						target='_blank'
						rel='noreferrer'
						className='underline text-green-600'>
						Research Papers
					</a>
					<p className='text-gray-400 mt-2'>
						Final year university module. A research paper based react app using a rest API to access data from an
						SQLite database.
					</p>
					<p className='text-green-600 text-sm'>Tech stack: React, PHP, SQLite</p>
				</div>
			</div>
			<h4 className='text-lg md:text-xl my-8'>UX designs</h4>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
				<div>
					<img src={cb} alt='' className='mb-4 h-64 w-full' />
					<p className='text-green-600 mb-2'>Chris Blackburn</p>
					<p className='text-gray-400'>
						Wireframe UX opening concept for local RIBA Architect, Chris Blackburn. Project currently in the design
						stage exploring a more contemporary feel for an updated website for the client.
					</p>
					<p className='text-green-600 text-sm'>Design tool: Framer</p>
				</div>
				<div>
					<img src={bbu} alt='' className='mb-4 h-64 w-full' />
					<p className='text-green-600 mb-2'>BBU</p>
					<p className='text-gray-400'>
						High fidelity wireframes/mockup designs for gaming community Built By Us. BBU currently operate on Discord
						with hundreds of active members. The project was developed as part of a university team module.{' '}
					</p>
					<p className='text-green-600 text-sm'>Design tool: Adobe XD</p>
				</div>
			</div>
		</section>
	);
};

export default Projects;
