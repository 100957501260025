import React from 'react';
import { HashLink } from 'react-router-hash-link';

const Nav = () => {
	return (
		//desktop menu
		<div>
			<nav className='hidden md:flex pr-1 items-center'>
				<ul className='flex gap-4 items-center font-bold'>
					<li>
						<HashLink to='/' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
							Home
						</HashLink>
					</li>
					<li>
						<HashLink to='/#about' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
							About
						</HashLink>
					</li>
					<li>
						<HashLink to='/#projects' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
							Projects
						</HashLink>
					</li>
					<li>
						<HashLink to='/#skills' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
							Skills
						</HashLink>
					</li>
					<li className='border-2 border-green-300 py-1 px-2 rounded text-green-300'>
						<HashLink to='/#hire-me' scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
							Hire Me
						</HashLink>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Nav;
